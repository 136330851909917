<template>
    <v-app-bar app clipped-left color="grey lighten-5" flat>
        <v-row no-gutters class="mx-sm-n3 mx-xs-n3">
            <!-- App icon -->
            <div>
                <v-img
                    v-if="$vuetify.breakpoint.mdAndUp"
                    src="@/assets/PROJECT_AGENDA.png"
                    max-height="50"
                    max-width="210"
                    contain
                />

                <v-img
                    v-if="$vuetify.breakpoint.smAndDown"
                    src="@/assets/PROJECT_AGENDA_Isotipo.png"
                    max-height="50"
                    max-width="50"
                    contain
                    class="ml-n3 ml-sm-0 px-0 mr-0"
                />
            </div>

            <!-- Spacer -->
            <v-spacer />

            <div class="d-flex">
                <!-- Search field -->
                <v-combobox
                    v-if="!user.operatorRole"
                    class="shrink"
                    :items="getQuotesByCustomizedParameters"
                    rounded
                    outlined
                    hide-details
                    dense
                    prepend-inner-icon="mdi-magnify"
                    append-icon=""
                    ref="numberComboBox"
                    v-model="comboBoxDummyModel"
                    @change="onAutoCompleteSelection"
                    @keyup="customOnChangeHandler"
                    @paste="customOnChangeHandler"
                    :search-input.sync="search"
                    item-text="name"
                    :filter="filter"
                    :loading="loading"
                    placeholder="Search"
                    :style="{
                        'margin-top': $vuetify.breakpoint.smAndDown
                            ? '4px'
                            : '',
                        'max-width': $vuetify.breakpoint.smAndDown
                            ? '170px'
                            : '300px',
                    }"
                >
                    <template v-slot:append>
                        <v-btn
                            class="text-transform-none"
                            text
                            elevation="0"
                            color="primary"
                            small
                            v-if="
                                comboBoxModel && !$vuetify.breakpoint.smAndDown
                            "
                            @click="searchQuote(true)"
                        >
                            Search
                        </v-btn>
                    </template>
                </v-combobox>

                <!-- notifications bell -->
                <NotificationsBell
                    class="px-md-4 px-0"
                    v-if="!user.operatorRole"
                />

                <!-- User name and avatar -->
                <v-btn
                    :disabled="user.operatorRole"
                    icon
                    class="ma-0 pa-0 px-md-4 px-0"
                    @click="openDialog"
                    :style="{
                        'margin-top': $vuetify.breakpoint.smAndDown && '6px',
                    }"
                >
                    <!-- Avatar -->
                    <v-avatar size="40">
                        <v-img
                            v-if="profilePicURL"
                            :src="profilePicURL"
                            :alt="user.name"
                        />
                        <v-icon x-large color="#707070" v-else
                            >mdi-account-circle</v-icon
                        >
                    </v-avatar>
                </v-btn>

                <!-- Operator LogOut -->
                <div class="ml-3" v-if="user.operatorRole">
                    <v-btn icon class="ms-0 pa-0" @click="signOut">
                        <v-icon large color="#707070">mdi-logout</v-icon>
                    </v-btn>
                </div>
            </div>
        </v-row>
        <v-dialog v-model="userDialog" persistent max-width="600px">
            <UserForm
                title="User Information"
                :cardProfile="true"
                :create-form="false"
                @closeDialog="closeDialog"
                :user="this.userToUpdate"
                :image="this.profilePicURL"
                :originalUser="this.originalUser"
                @replaceUser="this.replaceUser"
            />
        </v-dialog>
        <!--QUOTE-->
        <v-dialog :retain-focus="false" v-model="openQuoteDialog" fullscreen>
            <v-card v-if="openQuoteDialog" class="fill-height">
                <v-card-title class="ml-n4">
                    <v-btn icon @click="openQuoteDialog = false">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                    Quote
                </v-card-title>
                <v-card-text
                    class="px-0"
                    :style="`height:${this.heightPopUp}px`"
                >
                    <Quote
                        :id="comboBoxModel.id"
                        :quote="comboBoxModel"
                        :settings="settings"
                        :history="!!comboBoxModel.archive"
                    />
                </v-card-text>
            </v-card>
        </v-dialog>
    </v-app-bar>
</template>

<script>
import UserForm from '@/components/Users/UserForm'
import NotificationsBell from '@/components/Notifications/NotificationsBell.vue'
import firebase, { storage } from '@/services/firebase'
import { mapActions, mapState, mapMutations, mapGetters } from 'vuex'
import API from '@/services/api'
import _ from 'lodash'
import { auth, messaging, vapidKey } from '@/services/firebase'
export default {
    name: 'AppBar',
    components: {
        UserForm,
        NotificationsBell,
        Quote: () => import('@/views/Quotes/Quote.vue'),
    },
    data() {
        return {
            heightPopUp: 0,
            openQuoteDialog: false,
            folderPictures: 'users_pictures',
            companyId: JSON.parse(localStorage.getItem('company')),
            userId: JSON.parse(localStorage.getItem('userId')),
            user: JSON.parse(localStorage.getItem('user')),
            folderLogo: 'company_logo',
            userDialog: false,
            userToUpdate: null,
            profilePicURL: null,
            loadingError: false,
            errorMsg: null,
            originalUser: null,
            search: null,
            loading: false,
            comboBoxDummyModel: null,
            comboBoxModel: null,
            notificationsMenu: false,
            notifications: [
                { title: 'Nueva notificación', message: '¡Bienvenido!' },
                { title: 'Aviso', message: 'Tienes nuevos mensajes.' },
            ],
        }
    },
    computed: {
        ...mapState([
            'companyRoles',
            'usersPermissions',
            'settings',
            'userRef',
        ]),
        ...mapGetters(['getQuotesByCustomizedParameters']),
    },

    watch: {
        search() {
            if (!this.search) {
                this.setQuotesByCustomizedParameters([])
            } else {
                this.searchQuote()
            }
        },
        comboBoxModel() {
            if (this.comboBoxModel.id) {
                this.openQuote()
            }
        },
    },
    mounted() {
        this.onResize()
    },
    methods: {
        ...mapMutations([
            'setQuotesByCustomizedParameters',
            'setSelectedQuoteInGeneral',
            'setErrorItems',
        ]),
        ...mapActions(['resetStateAction']),
        onResize() {
            this.heightPopUp = window.innerHeight - 62
        },
        openQuote() {
            this.openQuoteDialog = true
        },
        onChangeHandler() {
            if (!this.comboBoxModel) {
                this.setQuotesByCustomizedParameters([])
            } else {
                this.searchQuote()
            }
        },
        onAutoCompleteSelection() {
            this.comboBoxModel = this.comboBoxDummyModel
            this.onChangeHandler()
        },
        customOnChangeHandler() {
            let vm = this
            setTimeout(function() {
                if (vm.$refs.numberComboBox) {
                    vm.comboBoxModel = vm.$refs.numberComboBox.internalSearch
                    vm.onChangeHandler()
                }
            })
        },
        async searchQuote(newSearch = undefined) {
            try {
                this.errorMsg = ''
                this.loadingError = false
                const db = firebase.firestore()
                const query = db
                    .collection('companies')
                    .doc(this.companyId)
                    .collection('quotes')
                var data = undefined
                if (newSearch) {
                    if (this.comboBoxModel.id) {
                        data = await query.get({ source: 'cache' })
                        this.openQuote()
                    } else {
                        this.loading = true
                        data = await query.get({ source: 'server' })
                    }
                } else {
                    data = await query.get({ source: 'cache' })
                    if (data.empty) {
                        this.loading = true
                        data = await query.get({ source: 'server' })
                    }
                }
                let quotes = []
                data.forEach(doc => {
                    quotes.push({ id: doc.id, ...doc.data() })
                })
                this.setQuotesByCustomizedParameters(quotes)
            } catch (error) {
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
            } finally {
                this.loading = false
            }
        },
        filter(item, queryText) {
            if (item.header) return false
            return (
                item.name.toLowerCase().includes(queryText.toLowerCase()) ||
                `${item.number}`.includes(queryText) ||
                (item.tags && item.tags.includes(queryText))
            )
        },
        openDialog: function() {
            const userToUpdate = this.user
            this.userToUpdate = _.cloneDeep(userToUpdate)
            this.originalUser = _.cloneDeep(userToUpdate)
            this.userDialog = true
        },
        ...mapActions([
            'replaceUserAction',
            'removeUserAction',
            'addSettingsAction',
            'addRolesAction',
            'addPermissionsAction',
        ]),
        closeDialog: function() {
            this.userDialog = false
        },
        replaceUser: function(id, user) {
            this.user = Object.assign(this.user, user)
            this.profilePicURL = user.profilePicURL
            this.replaceUserAction({ id, user })
            localStorage.setItem('user', JSON.stringify(this.user))
        },
        getSettings: async function() {
            try {
                const {
                    data: { settings },
                } = await API.getSettings()
                const indexLogo = settings.findIndex(x => x.name == 'Company')
                if (settings[indexLogo].generalInfo.image.file) {
                    const path = `${this.companyId}/${this.folderLogo}`
                    const storageRef = storage().ref(path)
                    const profilePicRef = storageRef.child(
                        settings[indexLogo].generalInfo.image.file
                    )
                    await profilePicRef
                        .getDownloadURL()
                        .then(URL => {
                            settings[indexLogo].generalInfo.image.imageURL = URL
                        })
                        .catch(error => {
                            settings[indexLogo].generalInfo.image.imageURL = ''
                            error && true
                        })
                }
                let orderSettings = settings.filter(x => x.name == 'Company')
                orderSettings[1] = settings.find(x => x.name == 'Exchange')
                orderSettings[2] = settings.find(x => x.name == 'Permissions')
                orderSettings[3] = settings.find(x => x.name == 'Quotes')
                const calculations = this.userRef.permissions.find(
                    x => x == 'calculations'
                )

                if (calculations) {
                    const item = settings.find(x => x.name == 'Calculations')
                    if (item) {
                        orderSettings[4] = item
                    }
                }
                const finish = settings.find(x => x.name == 'FinishAndUCCode')
                if (finish) {
                    orderSettings.push(finish)
                }
                const processes = settings.find(x => x.name == 'Processes')
                const products = settings.find(x => x.name == 'Products')
                const invoicing = settings.find(x => x.name == 'Invoicing')
                if (invoicing) {
                    orderSettings.push(invoicing)
                }

                if (processes) {
                    const machines = {
                        name: 'Machines',
                        nameRoute: 'machinesSettings',
                        id: processes.id,
                    }
                    orderSettings.push(machines)
                }

                const workOrderTab = {
                    name: 'WorkOrders',
                    nameRoute: 'workOrderSettings',
                    processes: processes || {},
                    products: products || {},
                }
                orderSettings.push(workOrderTab)

                const operatorsTab = {
                    name: 'Operators',
                    nameRoute: 'operators',
                }
                orderSettings.push(operatorsTab)

                this.addSettingsAction(orderSettings)
                const settingsRoles = settings.filter(
                    x => x.name == 'Permissions'
                )
                const usersPermissions = []
                Object.keys(settingsRoles[0].permissions).map(key => {
                    for (let item of settingsRoles[0].permissions[key]) {
                        usersPermissions.push({
                            text: item.name,
                            value: item.permission,
                        })
                    }
                })
                this.addRolesAction(settingsRoles[0].roles)
                this.addPermissionsAction(usersPermissions)
            } catch (error) {
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
            }
        },
        openNotifications() {
            this.notificationsMenu = !this.notificationsMenu
        },
        signOut: async function() {
            try {
                this.loading = true
                localStorage.clear()
                //
                await auth().signOut()
                this.$router
                    .replace({ name: 'sign in' })
                    .catch(error => error && null)
                this.resetStateAction()
                this.loading = false
            } catch (error) {
                this.error = true
                this.errorMsg = error.message
            } finally {
                // unsubscribe from topic for notifications
                if (messaging) {
                    const currentToken = await messaging.getToken({ vapidKey })
                    await API.unsubscribeFromTopic(currentToken).catch(
                        error => {
                            console.log(error.message)
                        }
                    )
                }
            }
        },
    },
    async created() {
        if (this.user.picture) {
            const path = `${this.companyId}/${this.folderPictures}`
            const storageRef = storage().ref(path)
            const profilePicRef = storageRef.child(this.user.picture)
            await profilePicRef
                .getDownloadURL()
                .then(URL => {
                    this.profilePicURL = URL
                })
                .catch(error => {
                    this.profilePicURL = ''
                    error && true
                })
        }
        if (this.settings.length == 0) {
            this.getSettings()
        } else {
            const settingsRoles = this.settings.filter(
                x => x.name == 'Permissions'
            )
            const usersPermissions = []
            Object.keys(settingsRoles[0].permissions).map(key => {
                for (let item of settingsRoles[0].permissions[key]) {
                    usersPermissions.push({
                        text: item.name,
                        value: item.permission,
                    })
                }
            })
            this.addRolesAction(settingsRoles[0].roles)
            this.addPermissionsAction(usersPermissions)
        }
    },
}
</script>

<style scoped></style>
