<template>
  <v-footer height="40" class="text-body-2" app color="grey lighten-5">
    <span>
      Designed and Buillt by Boston Project, LLC.
    </span>
    <v-spacer />
    <span> Copyright © SIGMA {{ new Date().getFullYear() }} </span>
  </v-footer>
</template>

<script>
export default {
  name: "Footer",
};
</script>
