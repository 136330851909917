<template>
    <v-app v-resize="onResize" ref="container">
        <AppBar ref="appBar" />
        <div>
            <NavDrawer />
        </div>
        <v-main :style="`height: ${height}px`">
            <router-view />
            <Errors />
        </v-main>
        <Footer ref="footer" />
    </v-app>
</template>

<script>
import NavDrawer from '@/components/Layout/NavDrawer'
import AppBar from '@/components/Layout/AppBar'
import Footer from '@/components/Layout/Footer'
import Errors from '@/components/Layout/Errors'

export default {
    name: 'Content',
    components: {
        NavDrawer,
        AppBar,
        Footer,
        Errors,
    },
    data() {
        return {
            height: 0,
            userId: JSON.parse(localStorage.getItem('userId')),
            companyId: JSON.parse(localStorage.getItem('company')),
        }
    },
    methods: {
        onResize() {
            const {
                container: { clientHeight: containerHeight },
                appBar: { clientHeight: appBarHeight },
                footer: { clientHeight: footerHeight },
            } = this.$refs
            this.height = containerHeight - -appBarHeight - footerHeight
        },
    },
}
</script>
